import { MOBILMEDIA } from './helper/constants';
import { $, $$ } from './helper/utils';
import outline from './helper/outline';

const sliderMap = new Map();
const sliderDragging = new Map();

outline();

let mobileNavLoaded = false;

const loadMobileNav = () => {
  import('./components/mobileNav.js')
    .then((mobileNav) => {
      mobileNavLoaded = true;
      return mobileNav.default();
    }).catch((err) => {
      console.warn(`Chunk mobileNav.js loading failed: ${err}`);
    });
};

export default function () {
  // const isMobile = document.documentElement.classList.contains('is-mobile');

  if ($$('.linked-product') !== null) {
    import('./components/productShowcase')
      .then((productShowcase) => productShowcase.default())
      .catch((err) => {
        console.warn('Chunk accordion.js loading failed', err);
      });
  }

  if ($('.siema') !== null) {
    import('./components/slider')
      .then((slider) => slider.default(sliderMap, sliderDragging))
      .catch((err) => {
        console.warn('Chunk slider.js loading failed', err);
      });
  }

  if ($('.custom-select') !== null) {
    import('./components/forms/select')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }

  if ($('.custom-modal') !== null) {
    import('./components/customModal')
      .catch((err) => {
        console.warn(`Chunk ageGate.js loading failed: ${err}`);
      });
  }

  if ($('.agegate') !== null && document.documentElement.classList.contains('hideag') === false) {

    import('./components/ageGate.js')
      .then((agegate) => agegate.default())
      .catch((err) => {
        console.warn(`Chunk agegate.js loading failed: ${err}`);
      });
  }

  if ($('a[href^="#"]') !== null) {
    import('./components/smoothhref')
      .catch((err) => {
        console.warn('Chunk forms/select.js loading failed', err);
      });
  }

  if ($('.date-picker') !== null) {
    import('./components/datePicker.js').then((date) => date.default()).catch((err) => {
      console.warn(`Chunk datePicker.js loading failed: ${err}`);
    });
  }

  if ($$('.nav-tabs, [data-toggle="collapse"]').length) {
    const fadeTab = (e) => {
      const href = e.relatedTarget.getAttribute('href');
      gsap.fromTo(href, {
        duration: 0.3,
        opacity: 0,
      }, {
        opacity: 1,
        ease: 'linear',
      });
    };

    import('../libs/bs-4-native')
      .then((bsn) => {
        if ('bootstrapNative' in window === false) {
          window.bootstrapNative = bsn;
        }

        for (const el of $$('.nav-tabs .tab-link')) {
          // fade in new tab content
          el.addEventListener('hide.bs.tab', fadeTab, false);
        }
      })
      .catch((err) => {
        console.warn('Chunk bs4-native.js loading failed', err);
      });
  }

  if ($('.sso-form') !== null) {
    import('./components/sso.js')
      .then((sso) => sso.default())
      .catch((err) => {
        console.warn(`Chunk sso.js loading failed: ${err}`);
      });
  }

  if ($('.hide-duringvideo') !== null) {
    import('./components/slot-machine.js')
      .then((slotMachine) => slotMachine.default())
      .catch((err) => {
        console.warn(`Chunk sso.js loading failed: ${err}`);
      });
  }

  import('./components/header.js')
    .then((header) => header.default())
    .catch((err) => {
      console.warn(`Chunk header.js loading failed: ${err}`);
    });

  // conditionaly load scripts that are only needed for certain viewports
  // but only if the viewport changed
  MOBILMEDIA.addListener((e) => {
    if (e.matches !== false) {
      // eslint-disable-next-line
      if (mobileNavLoaded === false) loadMobileNav();
    }
  });

  // conditionaly load scripts that are only needed for certain viewports
  if (MOBILMEDIA.matches !== false) {
    loadMobileNav();
  }

  if ($('.register') !== null) {
    import('./components/register.js').then((register) => register.default()).catch((err) => {
      console.warn(`Chunk register.js loading failed: ${err}`);
    });
  }

  // import('./components/backToTop')
  //     .catch((err) => {
  //         console.warn('Chunk backToTop.js loading failed', err);
  //     });

  if ($('.back-to-top') !== null) {
    import('./components/backToTop.js').then((backToTop) => backToTop.init()).catch((err) => {
      console.warn(`Chunk backToTop.js loading failed: ${err}`);
    });
  }
  if ($('.scrollto') !== null) {
    import('./components/smoothScroll.js').then((scroll) => scroll.init()).catch((err) => {
      console.warn(`Chunk smoothScroll.js loading failed: ${err}`);
    });
  }
}
